 
<template>
  <!--begin::Container-->
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white">
               
              </div>
              <div class="mt-5">
                <h4 class="text-white ">
                  
                </h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">

      <div class="container">
    <div class="text-lg-right">

         <v-btn
         elevation="9"
      rounded
      color="success"
      href="/market/create-invoice"
    >
      <v-icon left>
        mdi-pencil
      </v-icon>
      Back
    </v-btn>

      

                                                    </div>

    </div>
<div class="row">




<div class="col-lg-12">

<div>

<div class="container">
   

     
 

                        <div class="row ">
                            <div class="col-lg-4">
                                <v-card class="card card-custom card-stretch">
                                    
                                    <div class="card-header">
                                        <div class="card-title">
													<h3 class="card-label">Billing Info</h3>
								        </div>
                                    </div>
                                    <div class="card-body">

                                        <h5 class="font-family-primary font-weight-semibold">Great Local Dealer</h5>
                                        
                                        <p class="mb-2"><span class="font-weight-semibold mr-2">Address:</span> 300 Main st <br>Any Town, CA 90210</p>
                                        <p class="mb-2"><span class="font-weight-semibold mr-2">Phone:</span> (123) 456-7890</p>
            
                                    </div>
                                </v-card>
                            </div> <!-- end col -->
                         <div class="col-lg-4">
                                <v-card class="card card-custom card-stretch">
                                    
                                    <div class="card-header">
                                        <div class="card-title">
													<h3 class="card-label">Shipping Info</h3>
								        </div>
                                    </div>
                                    <div class="card-body">

                                       <h5 class="font-family-primary font-weight-semibold">Great Local Dealer Recieving</h5>
                                        
                                        <p class="mb-2"><span class="font-weight-semibold mr-2">Address:</span> 300 Main st <br> Any Town, CA 90210</p>
                                        <p class="mb-2"><span class="font-weight-semibold mr-2">Phone:</span> (123) 456-7890</p> 
            
                                    </div>
                                </v-card>
                            </div> <!-- end col -->
                        
        
                            <div class="col-lg-4">
                                <v-card class="card card-custom card-stretch">
                                    <div class="card-header">
                                        <div class="card-title">
													<h3 class="card-label">Order Info</h3>
								        </div>
                                    </div>
                                    <div class="card-body ">
                                        
                                        <ul class="list-unstyled mb-0">
                                            <li>
                                                <p class="mb-2"><span class="font-weight-semibold mr-2">Purchase Order #:</span> 100056565</p>
                                                <p class="mb-2"><span class="font-weight-semibold mr-2">Payment Type:</span> NET TERMS</p>
                                                                                                <p class="mb-2"><span class="font-weight-semibold mr-2">Status:</span>Shipped</p>

                                            </li>
                                        </ul>
                                        
<button type="button" class="btn btn-primary waves-effect waves-light mb-1 mr-2"><i class="mdi mdi-basket mr-1"></i> View Payment </button>            
                                    </div>
                                </v-card>
                            </div> <!-- end col -->
    </div>
                        <!-- end row -->
   
        <div class="row  ">
                
                            <div class="col-lg-12">
                                <v-card class="card card-custom card-stretch">
                                    
                                    <div class="card-header card-header-tabs-line">
        <div class="card-title">
            <h3 class="card-label">Items from Order  #100056565</h3>
        </div>
        <div class="card-toolbar">  
        
        </div>
    </div>
                                    
                                    
                                    <div class="card-body">
<div class="tab-content">
            <div id="kt_tab_pane_1_3" class="tab-pane fade show active" role="tabpanel" aria-labelledby="kt_tab_pane_1_3">
                          <!--begin: Datatable-->
										<table id="kt_datatable" class="table  table-checkable" style="margin-top: 13px !important">
											<thead>
												<tr>
													<th>Line ID</th>
													<th>SKU</th>
                                                    <th>Thumb</th>
													<th>Item</th>
                                                     <th style="width:100px" >Qty</th>
													<th>Price</th>
                                                    <th>Extended Price</th>
                                                    <th>Return Qty</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>1</td>
                                                    
													<td>0338-1009</td>
                                                    <td><v-img class="d-flex align-self-center mr-3 rounded-circle" src="/media/rollerskate.png" alt="Generic placeholder image"  width="50" height="50"> </v-img></td>
													<td>Mens Bike Red - Small</td>
													<td>10</td>
                                                    <td>$2445.00</td>
                                                    <td>$24450.00</td>
                                                     <td>    <v-text-field
                                                     placeholder="10"
                                                        outlined
                                                        ></v-text-field>   </td>
												</tr>
												<tr>
													<td>2</td>
                                                    
													<td>4456-1009</td>
                                                    <td><v-img class="d-flex align-self-center mr-3 rounded-circle" src="/media/rollerskate.png" alt="Generic placeholder image"  width="50" height="50"> </v-img></td>
													<td>Mens Bike Blue - Large</td>
													<td>10 ordered <br> 10 invoiced</td>
                                                    <td>$2700.00</td>
                                                    <td>$27000.00</td>
                                                    <td><v-text-field
           
            placeholder="10"
            outlined
          ></v-text-field></td>
												</tr>
												
													<tr>
													<td>3</td>
                                                    
													<td>0338-1010</td>
                                                    <td> <v-img class="d-flex align-self-center mr-3 rounded-circle" src="/media/rollerskate.png" alt="Generic placeholder image"  width="50" height="50"> </v-img></td>
													<td>Spring Hammock- Green</td>
													<td>10</td>
                                                    <td>$2700.00</td>
                                                    <td>$27000.00</td>
                                                    <td><v-text-field
           
            placeholder="10"
            outlined
          ></v-text-field></td>
												</tr>
												<tr>
													<td></td>
                                                    
													<td></td>
                                                    <td></td>
													<td></td>
													<td></td>
                                                    <td></td>
													<td></td>
                                                    <td></td>
                                                    <td>Subtotal</td>
                                                    <td>$27000.00</td>
                                                    <td></td>
                                                    <td></td>
												</tr>
											<tr>
													<td></td>
                                                    
													<td></td>
                                                    <td></td>
													<td></td>
                                                    <td></td>
													<td></td>
                                                    <td></td>
                                                    <td> </td>
                                                    <td>Shipping to refund</td>
                                                    <td><v-text-field
           
            placeholder="$10.00"
            outlined
          ></v-text-field></td>
                                                    <td></td>
                                                    <td></td>
												</tr>

                                               
                                                            <tr>
													<td></td>
                                                    
													<td></td>
                                                    <td></td>
													<td></td>
													<td></td>
                                                    <td></td>
													<td></td>
                                                    <td></td>
                                                    <td>Restock Fee</td>
                                                    <td><v-text-field
           
            placeholder="10"
            outlined
          ></v-text-field></td>
                                                    <td></td>
                                                  <td> </td>
												</tr>

                                                <tr>
													<td></td>
                                                    
													<td></td>
                                                    <td></td>
													<td></td>
													<td></td>
                                                    <td></td>
													<td></td>
                                                    <td></td>
                                                    <td>Total</td>
                                                    <td>$127010.00</td>
                                                    <td></td>
                                                  <td> </td>
												</tr>
											</tbody>
										</table>
										<!--end: Datatable-->
                                         <div class="row">
                                             <div class="col-lg-6">
                                                 Send email checkbox
                                             </div>
                                             <div class="col-lg-6">
                                          
 <v-btn>   Refund Order Offline - no Payment  </v-btn>
                                           <v-btn>   Refund Order  </v-btn>
                                             </div>
                                        </div>
                                   
            </div>
           
      
        </div>
                                        
                                   
                                        
                                        
                                    </div>
                                </v-card>
                            </div>
                        </div>
                        <!-- end row -->
                        
                    
                  
                    
                    
                    
                    
                    
                    
                    
                    

    
    
        </div>     
</div> 


 

    
</div>

</div>


      
 
    </div>
  </div>
</template>


<script>

  export default {
    
    data: () => ({
      dialog: false,
       orderTab: "Information",
      selected: 2,
      orders: [],
      editedIndex: -1,
      editedItem: {
        id: '',
        name: 0,
        user: 0,
        date: 0,
        total: 0,
        status: 0,
      },
      defaultItem: {
        id: '',
        invoiceid: '',
        name: 0,
        user: 0,
        date: 0,
        total: 0,
        status: 0,
      },
    }),
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    created () {
      this.initialize()
    },
  }
</script>






<style lang="scss">
.img-raised {
  -webkit-box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.54),
    0 8px 10px -5px rgba(0, 0, 0, 0.7);
}
.md-card-pricing ul {
  list-style: none;
  padding: 0;
  max-width: 240px;
  margin: 10px auto;
}
.md-card-pricing ul li {
  color: #fff;
  text-align: center;
  padding: 12px 0;
  font-size: 1.5rem;
  border-bottom: 3px solid rgba(255, 255, 255, 0.3);
}
.follow {
  position: absolute;
  top: -28px;
  right: 7px;
}
.lato-header {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
  font-family: "Lato", sans-serif;
  font-size: 4rem;
  font-weight: 800;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  text-shadow: #ccc;
}
.lato {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
}
.section-text {
  p {
    font-size: 1.188rem;
    line-height: 1.5em;
    color: #333;
    margin-bottom: 30px;
  }
  .blockquote {
    p {
      font-size: 1.5rem;
    }
  }
}
.section-blog-info {
  padding-top: 30px;
  padding-bottom: 0;
  .btn {
    margin-top: 0;
    margin-bottom: 0;
  }
  .blog-tags {
    padding-top: 8px;
  }
  .card-profile {
    margin-top: 0;
    text-align: left;
    .description {
      font-size: 18px;
    }
    .btn {
      margin-top: 25px;
    }
  }
}
.section-comments {
  .title {
    margin-bottom: 30px;
  }
}
.comment {
  p {
    color: #ccc;
  }
}
.section-projects-1 {
  .title {
    margin-top: 10px;
  }
}
.section-projects-2 {
  .title {
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .description {
    margin-bottom: 130px;
  }
  .card-title {
    margin-bottom: 5px;
  }
  .category {
    color: #ccc;
    margin-top: 5px;
  }
  .card-description {
    margin-top: 20px;
  }
  .md-card {
    text-align: center;
  }
}
.section-projects-3 {
  .title {
    margin-bottom: 80px;
    margin-top: 5px;
  }
  .md-card {
    margin-bottom: 30px;
    margin-top: 60px;
    text-align: center;
  }
  .category {
    margin-bottom: 5px;
  }
  .card-title {
    margin-top: 5px;
  }
}
.section-projects-4 {
  .title {
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .title + .description {
    margin-bottom: 130px;
  }
  .info {
    padding: 0;
    p {
      margin: 0;
    }
  }
  .md-card-background {
    margin-bottom: 0;
    .md-card-content {
      padding-top: 140px;
      padding-bottom: 140px;
    }
  }
  hr {
    margin: 70px auto;
    max-width: 970px;
  }
  .md-card {
    margin-top: 0;
  }
}
.back-background .card-title,
.front-background .card-title,
.md-card-background .card-title {
  color: #ffffff !important;
  margin-top: 10px;
}
.section-projects-1 {
  .title {
    margin-top: 10px;
  }
}
.section-projects-2 {
  .title {
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .description {
    margin-bottom: 130px;
  }
  .card-title {
    margin-bottom: 5px;
  }
  .category {
    color: #ccc;
    margin-top: 5px;
  }
  .card-description {
    margin-top: 20px;
  }
  .md-card {
    text-align: center;
  }
}
.section-projects-3 {
  .title {
    margin-bottom: 80px;
    margin-top: 5px;
  }
  .md-card {
    margin-bottom: 30px;
    margin-top: 60px;
    text-align: center;
  }
  .category {
    margin-bottom: 5px;
  }
  .card-title {
    margin-top: 5px;
  }
}
.section-projects-4 {
  .title {
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .title + .description {
    margin-bottom: 130px;
  }
  .info {
    padding: 0;
    p {
      margin: 0;
    }
  }
  .md-card-background {
    margin-bottom: 0;
    .md-card-content {
      padding-top: 140px;
      padding-bottom: 140px;
    }
  }
  hr {
    margin: 70px auto;
    max-width: 970px;
  }
  .md-card {
    margin-top: 0;
  }
}
.skew-separator {
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 800px;
    -webkit-transform: skewY(-8deg);
    transform: skewY(-8deg);
    background: #fff;
    top: 88%;
    z-index: 1;
  }
  &.skew-top {
    &:after {
      height: 370px !important;
      top: -220px !important;
    }
  }
  &.skew-mini {
    &:after {
      top: 79% !important;
    }
  }
}
.section-shaped {
  position: relative;
  overflow: hidden;
  &.section-hero {
    &:before {
      top: 680px;
    }
  }
  .stars-and-coded {
    margin-top: 8rem;
  }
  .shape {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    span {
      position: absolute;
    }
    + .container {
      position: relative;
      // padding-top: 6rem;
      // padding-bottom: 0rem;
      height: 100%;
    }
    &.shape-skew {
      + .container {
        padding-top: 0;
        .col {
          margin-top: -100px;
        }
      }
      + .shape-container {
        padding-top: 18rem;
        padding-bottom: 19rem;
      }
    }
  }
  .shape-skew {
    transform: skewY(-4deg);
    transform-origin: 0;
    span {
      transform: skew(4deg);
    }
  }
  // Color variations
  .shape-primary {
    background: linear-gradient(
      150deg,
      shapes-primary-color("step-1-gradient-bg") 15%,
      shapes-primary-color("step-2-gradient-bg") 70%,
      shapes-primary-color("step-3-gradient-bg") 94%
    );
    :nth-child(1) {
      background: shapes-primary-color("span-1-bg");
    }
    :nth-child(2) {
      background: shapes-primary-color("span-2-bg");
    }
    :nth-child(3) {
      background: shapes-primary-color("span-3-bg");
    }
    :nth-child(4) {
      background: shapes-primary-color("span-4-bg");
    }
    :nth-child(5) {
      background: shapes-primary-color("span-5-bg");
    }
  }
  .shape-default {
    background: linear-gradient(
      150deg,
      shapes-default-color("step-1-gradient-bg") 15%,
      shapes-default-color("step-2-gradient-bg") 70%,
      shapes-default-color("step-3-gradient-bg") 94%
    );
    :nth-child(1) {
      background: shapes-default-color("span-1-bg");
    }
    :nth-child(2) {
      background: shapes-default-color("span-2-bg");
    }
    :nth-child(3) {
      background: shapes-default-color("span-3-bg");
    }
    :nth-child(4) {
      background: shapes-default-color("span-4-bg");
    }
    :nth-child(5) {
      background: shapes-default-color("span-5-bg");
    }
  }
  .shape-light {
    background: linear-gradient(
      150deg,
      shapes-light-color("step-1-gradient-bg") 15%,
      shapes-light-color("step-2-gradient-bg") 70%,
      shapes-light-color("step-3-gradient-bg") 94%
    );
    :nth-child(1) {
      background: shapes-light-color("span-1-bg");
    }
    :nth-child(2) {
      background: shapes-light-color("span-2-bg");
    }
    :nth-child(3) {
      background: shapes-light-color("span-3-bg");
    }
    :nth-child(4) {
      background: shapes-light-color("span-4-bg");
    }
    :nth-child(5) {
      background: shapes-light-color("span-5-bg");
    }
  }
  .shape-dark {
    background: linear-gradient(
      150deg,
      shapes-dark-color("step-1-gradient-bg") 15%,
      shapes-dark-color("step-2-gradient-bg") 70%,
      shapes-dark-color("step-3-gradient-bg") 94%
    );
    :nth-child(1) {
      background: shapes-dark-color("span-1-bg");
    }
    :nth-child(2) {
      background: shapes-dark-color("span-2-bg");
    }
    :nth-child(3) {
      background: shapes-dark-color("span-3-bg");
    }
    :nth-child(4) {
      background: shapes-dark-color("span-4-bg");
    }
    :nth-child(5) {
      background: shapes-dark-color("span-5-bg");
    }
  }
  .shape-style-1 {
    span {
      height: 120px;
      width: 120px;
      border-radius: 50%;
    }
    .span-200 {
      height: 200px;
      width: 200px;
    }
    .span-150 {
      height: 150px;
      width: 150px;
    }
    .span-100 {
      height: 100px;
      width: 100px;
    }
    .span-75 {
      height: 75px;
      width: 75px;
    }
    .span-50 {
      height: 50px;
      width: 50px;
    }
    :nth-child(1) {
      left: -4%;
      bottom: auto;
      background: rgba(255, 255, 255, 0.1);
    }
    :nth-child(2) {
      right: 4%;
      top: 10%;
      background: rgba(255, 255, 255, 0.1);
    }
    :nth-child(3) {
      top: 280px;
      right: 5.66666%;
      background: rgba(255, 255, 255, 0.3);
    }
    :nth-child(4) {
      top: 320px;
      right: 7%;
      background: rgba(255, 255, 255, 0.15);
    }
    :nth-child(5) {
      top: 38%;
      left: 1%;
      right: auto;
      background: rgba(255, 255, 255, 0.05);
    }
    :nth-child(6) {
      width: 200px;
      height: 200px;
      top: 44%;
      left: 10%;
      right: auto;
      background: rgba(255, 255, 255, 0.15);
    }
    :nth-child(7) {
      bottom: 50%;
      right: 36%;
      background: rgba(255, 255, 255, 0.04);
    }
    :nth-child(8) {
      bottom: 70px;
      right: 2%;
      background: rgba(255, 255, 255, 0.2);
    }
    :nth-child(9) {
      bottom: 1%;
      right: 2%;
      background: rgba(255, 255, 255, 0.1);
    }
    :nth-child(10) {
      bottom: 1%;
      left: 1%;
      right: auto;
      background: rgba(255, 255, 255, 0.05);
    }
    // shape color variations
    &.shape-primary {
      background: linear-gradient(
        150deg,
        shapes-primary-color("step-1-gradient-bg") 15%,
        shapes-primary-color("step-2-gradient-bg") 70%,
        shapes-primary-color("step-3-gradient-bg") 94%
      );
    }
    &.shape-default {
      background: linear-gradient(
        150deg,
        shapes-default-color("step-1-gradient-bg") 15%,
        shapes-default-color("step-2-gradient-bg") 70%,
        shapes-default-color("step-3-gradient-bg") 94%
      );
    }
    &.shape-light {
      background: linear-gradient(
        150deg,
        shapes-light-color("step-1-gradient-bg") 15%,
        shapes-light-color("step-2-gradient-bg") 70%,
        shapes-light-color("step-3-gradient-bg") 94%
      );
    }
    &.shape-dark {
      background: linear-gradient(
        150deg,
        shapes-dark-color("step-1-gradient-bg") 15%,
        shapes-dark-color("step-2-gradient-bg") 70%,
        shapes-dark-color("step-3-gradient-bg") 94%
      );
    }
  }
  // Styles - works with a color variation class (e.g shapes-default)
  .shape-style-2 {
    span {
      height: 190px;
    }
    .span-sm {
      height: 100px;
    }
    :nth-child(1) {
      width: 33.33333%;
      top: 0;
      left: -16.66666%;
    }
    :nth-child(2) {
      width: 33.33333%;
      top: 0;
      left: 16.66666%;
      right: auto;
    }
    :nth-child(3) {
      width: 33.33333%;
      left: 49.99999%;
      bottom: auto;
    }
    :nth-child(4) {
      width: 33.33333%;
      top: 55%;
      right: -16.66666%;
    }
    :nth-child(5) {
      width: 33.33333%;
      bottom: 0;
    }
    .shape-style-3 {
      span {
        height: 140px;
      }
      .span-sm {
        height: 100px;
      }
      :nth-child(1) {
        width: 66%;
        left: -16.66666%;
        bottom: auto;
      }
      :nth-child(2) {
        width: 40%;
        top: 54%;
        //height: 320px;
        right: -16.66666%;
      }
      :nth-child(3) {
        width: 33.33333%;
        top: 34%;
        left: -16.66666%;
        right: auto;
      }
      :nth-child(4) {
        width: 60%;
        bottom: 0;
        right: -16.66666%;
        opacity: 0.6;
      }
      :nth-child(5) {
        width: 33.33333%;
        bottom: 0;
      }
    }
  }
}
</style>

<style scoped>
.title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
  font-family: "Lato", sans-serif;
}
.v-application .title {
  font-size: 2.75rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: "Lato", sans-serif;
}
.page-header {
  height: 100vh;
  background-position: 50%;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.main-raised {
  margin: -180px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.main {
  background: #eee;
  position: relative;
  z-index: 3;
}
.md-button:not(.md-just-icon):not(.md-btn-fab):not(.md-icon-button):not(.md-toolbar-toggle)
  .md-ripple {
  padding: 12px 30px;
}
.md-button .md-ripple {
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.md-ripple {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(circle, #fff 100%, #000 0);
}
</style>


<style lang="scss">
.nav-link {
    color: #525f7f;
}
.nav-link {
    display: block;
    padding: .25rem .75rem;
}
 .nav .nav-item:not(:last-child) {
    border-bottom: 1px solid #5e72e4;
}
 .nav .nav-item {
    padding: 1rem 0;
}
 .nav .nav-item  .active {
    background-color: #ccc;
}
</style>
© 2021 GitHub, Inc.
Terms
Privacy
Security
Status
Help
Contact GitHub
Pricing
API
Training
Blog
About
